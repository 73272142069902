.web-development-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.web-development-container h2 {
  margin: 1rem 0 2rem;
  font-family: var(--secondary-font);
  font-size: 1.2rem;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
}

.web-development-container img {
  width: clamp(0px, 80%, 450px);
}
