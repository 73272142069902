/* ---------- Chachos home container ---------- */
.chachos-home-panel-container {
  margin-bottom: 3rem;
}

/* ---------- Chachos filter selection ---------- */
.chachos-filter-selection {
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

/* ---------- Chachos home container ---------- */
.chachos-home-panel-container-select {
  font-size: 0.85rem;
  border: 1px solid;
  border-color: var(--third-color);
  background-color: var(--third-color);
  border-radius: 10px;
  padding: 0.5rem;
  font-weight: 700;
  margin-top: 1rem;
}

/* ---------- Chachos home points title ---------- */
.chachos-home-panel-container h2 {
  margin: 1rem 0;
  font-family: var(--primary-font);
  font-size: 1.2rem;
  font-weight: 900;
  text-transform: uppercase;
}

/* ------------------------------------ */
/* ---------- CHACHOS SWIPER ---------- */
/* ------------------------------------ */
.chachos-swiper-container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.chachos-swiper {
  width: 100%;
  height: 500px;
}

.chachos-swiper-slide {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 0.5rem;
  position: relative;
}

.chachos-swiper::part(bullet) {
  background: var(--primary-color);
}

.chachos-swiper::part(bullet-active) {
  background: var(--primary-color);
}

.chachos-swiper-btn-back,
.chachos-swiper-btn-next {
  background-color: #000;
  cursor: pointer;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 500;
  transition: all ease-in-out 0.3s;
}

.chachos-swiper-btn-back {
  left: -2.2rem;
}

.chachos-swiper-btn-next {
  right: -2.2rem;
}

.chachos-swiper-btn-back span,
.chachos-swiper-btn-next span {
  color: white;
  transition: all ease-in-out 0.3s;
}

.chachos-swiper-btn-back:hover,
.chachos-swiper-btn-next:hover {
  background-color: white;
  border: 1px solid;
  border-color: var(--primary-color);
}

.chachos-swiper-btn-back:active,
.chachos-swiper-btn-next:active {
  scale: 0.8;
}

.chachos-swiper-btn-back:hover span,
.chachos-swiper-btn-next:hover span {
  color: var(--primary-color);
}

/* ---------------------------------------- */
/* ---------- CHACHOS STATS CARD ---------- */
/* ---------------------------------------- */

/* ---------- Top players ---------- */

.chachos-stats-content-card {
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  min-width: 320px;
  max-width: 500px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: all ease 0.3s;
}

.chachos-stats-content-card:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

.chachos-stats-content-card h3 {
  color: var(--secondary-color);
  font-size: 1.2rem;
  text-align: center;
  margin-block: 0.5rem 1rem;
}

.chachos-stats-content-card-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3rem;
  border-radius: 20px;
  transition: all ease 0.3s;
  margin-inline: 0.8rem;
  margin-bottom: 0.5rem;
}

.chachos-stats-content-card-top .top-player {
  display: flex;
  flex-direction: column;
  background-color: #e2f0ff;
  border-radius: 5px;
}

.chachos-stats-content-card-top .top-player .top-player-average {
  display: flex;
  justify-content: space-between;
  margin: 0 0.5rem 0.5rem 0.5rem;
  padding: 0.5rem;
  background-color: #bfdbfe;
  border-radius: 5px;
}

.chachos-stats-content-card-top .top-player .top-player-average h6 {
  font-weight: 400;
  font-size: 0.75rem;
  color: var(--primary-color);
}

.chachos-stats-content-card-top .top-player .top-player-average h6 i {
  color: #3b82f6;
}

.chachos-stats-content-card-top .top-player .top-player-average h6 strong {
  font-size: 0.8rem;
  margin-left: 0.5rem;
  color: var(--primary-color);
}

.chachos-stats-content-card-top .player {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  background-color: #e2f0ff;
  border-radius: 5px;
}

.chachos-stats-content-card-top .player .player-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.chachos-stats-content-card-top .player .player-info .player-rank {
  background-color: var(--secondary-color);
  height: 30px;
  width: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 400;
  font-size: 0.8rem;
}

.chachos-stats-content-card-top .player .player-info .player-name h4 {
  font-size: 0.95rem;
  font-weight: 500;
}

.chachos-stats-content-card-top .player .player-info .player-name h4 i {
  margin-left: 0.25rem;
  font-size: 1.2rem;
}

.chachos-stats-content-card-top
  .player
  .player-info
  .player-name
  h4
  i.fa-trophy {
  color: #e9b320;
}

.chachos-stats-content-card-top
  .player
  .player-info
  .player-name
  h4
  i.fa-medal {
  color: #9ca3af;
}

.chachos-stats-content-card-top
  .player
  .player-info
  .player-name
  h4
  i.fa-award {
  color: #a1620f;
}

.chachos-stats-content-card-top .player .player-info .player-name p {
  font-size: 0.78rem;
  font-weight: 300;
}

.chachos-stats-content-card-top .player .player-stat {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding-inline: 0.7rem;
  background-color: var(--secondary-color);
  border-radius: 20px;
}

.chachos-stats-content-card-top .player .player-stat span,
.chachos-stats-content-card-top .player .player-stat strong {
  color: white;
  font-size: 0.8rem;
}

/* ---------- Remaining players ---------- */

.chachos-stats-content-card-rest {
  display: flex;
  flex-direction: column;
  margin: 0 0.8rem 0.5rem 0.8rem;
  padding-top: 1rem;
  border-top: 2px solid #bfdbfe;
}

.chachos-stats-content-card-rest .player {
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  padding-bottom: 0.3rem;
}

.chachos-stats-content-card-rest .player .player-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.chachos-stats-content-card-rest .player .player-info .player-rank {
  background-color: #e2f0ff;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);
  font-weight: 400;
  font-size: 0.8rem;
}

.chachos-stats-content-card-rest .player .player-info .player-name h4 {
  font-size: 0.8rem;
  font-weight: 400;
}

.chachos-stats-content-card-rest .player .player-stat {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding-inline: 0.7rem;
  background-color: #e2f0ff;
  border-radius: 20px;
}

.chachos-stats-content-card-rest .player .player-stat span,
.chachos-stats-content-card-rest .player .player-stat strong {
  color: var(--secondary-color);
  font-size: 0.8rem;
}

/* ------------------------------------------ */
/* ---------- CHACHOS HOME DETAILS ---------- */
/* ------------------------------------------ */

/* ---------- Chachos home points content ---------- */
.chachos-points-content {
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
}

/* ---------- Chachos home points main table ---------- */
.chachos-points-main-table {
  background-color: white;
  font-size: 0.85rem;
  border-radius: 20px;
  overflow: hidden;
  display: inline-block;
  width: 50%;
  height: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: all ease 0.3s;
}

.chachos-points-main-table:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

.chachos-points-main-table table {
  width: 100%;
}

.chachos-points-main-table thead {
  background-color: var(--primary-color);
}

.chachos-points-main-table thead tr th {
  color: white;
}

.chachos-points-main-table tbody tr:nth-child(even) {
  background-color: color-mix(in srgb, var(--third-color), transparent 75%);
}

.chachos-points-main-table tbody tr:hover {
  background-color: var(--third-color);
  font-weight: 800;
}

.chachos-points-main-table tbody tr td {
  text-align: center;
}

/* ---------- Chachos home points cards ---------- */
.chachos-points-cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
}

.top-players-card {
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 20px;
  background-color: white;
  font-size: 0.85rem;
  padding: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: all ease 0.3s;
}

.top-players-card:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

.top-players-card img {
  position: absolute;
  top: -20px;
  left: -20px;
  width: 60px;
}
.top-players-card h5 {
  font-family: var(--accent-font);
  font-size: 1rem;
  font-weight: 400;
  width: 120px;
}

.top-players-card ul {
  list-style: none;
  padding-left: 1rem;
  border-left: 2px dotted;
  border-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.top-players-card ul li {
  display: flex;
  align-items: center;
}

.top-players-card ul li:first-child {
  background-color: var(--third-color);
  border-radius: 10px;
}

.top-players-card ul li:first-child p,
.top-players-card ul li:first-child span {
  font-size: 1.2rem;
  font-weight: 500;
  padding-block: 0.5rem;
}

.top-players-card ul li p {
  width: 25px;
  text-align: center;
}

.top-players-card ul li span {
  margin-left: 0.5rem;
  padding-inline: 0.5rem;
  padding-block: 0.2rem;
}

/* ---------- Media queries ---------- */
@media only screen and (max-width: 750px) {
  .chachos-stats-content {
    flex-direction: column;
  }

  .chachos-stats-content-card {
    flex-direction: column;
  }

  .chachos-points-content {
    flex-direction: column;
  }

  .chachos-points-main-table {
    width: 100%;
  }

  .chachos-points-cards {
    width: 100%;
  }

  .chachos-swiper-btn-back {
    left: -0.9rem;
    height: 25px;
    width: 25px;
  }

  .chachos-swiper-btn-next {
    right: -0.9rem;
    height: 25px;
    width: 25px;
  }

  .chachos-swiper-btn-back span,
  .chachos-swiper-btn-next span {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 500px) {
  .chachos-filter-selection {
    justify-content: center;
  }

  .chachos-home-panel-container-select {
    width: 50%;
  }

  .top-players-card h5 {
    font-size: 0.85rem;
  }

  .top-players-card ul li:first-child p,
  .top-players-card ul li:first-child span {
    font-size: 0.85rem;
    font-weight: 500;
    padding-block: 0.5rem;
  }
}
