/* Ajustes para pantallas pequeñas */
@media screen and (max-width: 600px) {
}

/* -------------------------------------------- */
/* --------------- DYNAMIC CLASSES -------------- */
/* -------------------------------------------- */
.btn-like-active i,
.btn-like-active p {
  color: var(--fourth-color);
}

/* --------------------------------------------------------------------- */
/* --------------- CUSTOM STYLES FROM IMPORTED COMPONENTS -------------- */
/* --------------------------------------------------------------------- */
.delete-btn-custom {
  margin: 0;
}

.delete-btn-custom i {
  margin: 0;
  font-size: 0.85rem;
  color: var(--fourth-color);
}

/* -------------------------------------------- */
/* --------------- CRONICA TITLE -------------- */
/* -------------------------------------------- */

.cronica-detail-title {
  width: clamp(300px, 100%, 800px);
  margin: 1rem auto 0rem;
}

.cronica-detail-title .back-btn,
.cronica-detail-title .back-btn i {
  color: var(--fourth-color);
  font-size: 0.8rem;
}

.cronica-detail-title .back-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.cronica-detail-title h1 {
  margin-top: 1rem;
  font-size: 2.5rem;
  font-family: var(--secondary-font);
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;
}

.cronica-detail-title h1 span {
  font-size: 2.5rem;
  font-family: var(--accent-font);
  font-weight: bolder;
  text-transform: none;
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  background-clip: text;
  color: transparent;
}

.cronica-detail-title h3 {
  font-size: 0.9rem;
  font-family: var(--secondary-font);
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-align: center;
}

.cronica-detail-title .cronica-detail-title-border {
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  height: 5px;
  width: 150px;
  margin: 1.5rem auto 0rem;
}

/* --------------------------------------------- */
/* --------------- CRONICA DETAIL -------------- */
/* --------------------------------------------- */

.cronica-detail {
  width: clamp(300px, 100%, 800px);
  margin: 2.5rem auto 0rem;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cronica-detail article .cronica-detail-image {
  position: relative;
  max-height: 400px;
}

.cronica-detail article .cronica-detail-image span {
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
  cursor: pointer;
  margin-left: 1rem;
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  padding: 0.35rem 1rem;
  border-radius: 10px;
  color: white;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
}

.cronica-detail article .cronica-detail-image img {
  width: 100%;
  max-height: 400px;
  object-fit: cover;
  display: block;
}

/* --------------------------------------------- */
/* --------------- CRONICA INFORMATION --------- */
/* --------------------------------------------- */
.cronica-author {
  width: clamp(300px, 100%, 800px);
  margin: 0.4rem auto 0rem;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid lightgray;
  padding: 0.5rem 1rem;
  display: flex;
  gap: 2rem;
  align-items: center;
}

.cronica-author img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  display: block;
  border-radius: 50%;
  background: linear-gradient(to bottom, lightgray, white, white);
}

.cronica-author .cronica-author-details {
  display: flex;
  gap: 0.7rem;
  flex-direction: column;
}

.cronica-author .cronica-author-details .cronica-author-details-kpi {
  display: flex;
  gap: 2.5rem;
}

.cronica-author
  .cronica-author-details
  .cronica-author-details-kpi
  .cronica-author-details-kpi-icon {
  display: flex;
  gap: 0.5rem;
  font-size: 0.8rem;
  align-items: center;
}

.cronica-author
  .cronica-author-details
  .cronica-author-details-kpi
  .cronica-author-details-kpi-icon
  i,
.cronica-author
  .cronica-author-details
  .cronica-author-details-kpi
  .cronica-author-details-kpi-icon
  p {
  color: gray;
}

.cronica-author .cronica-author-details .cronica-like-btn button {
  border: 1px solid lightgray;
  padding: 0.5rem;
  border-radius: 5px;
  width: 130px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  font-size: 0.85rem;
}

.cronica-author .cronica-author-details .cronica-like-btn .liked-btn {
  color: var(--fourth-color);
}

.cronica-author .cronica-author-details .cronica-like-btn .non-liked-btn {
  color: gray;
}

.cronica-author .cronica-author-details .cronica-like-btn button i {
  margin-right: 0.5rem;
}

/* --------------------------------------------- */
/* --------------- CRONICA BODY ---------------- */
/* --------------------------------------------- */
.cronica-body {
  width: clamp(300px, 100%, 800px);
  margin: 3rem auto 0rem;
  text-align: justify;
}

.cronica-images,
.cronica-audios,
.cronica-videos {
  margin-top: 2rem;
  margin-inline: auto;
  width: clamp(300px, 100%, 800px);
  display: flex;
  flex-direction: column;
}

.cronica-images h3,
.cronica-audios h3,
.cronica-videos h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #ccc;
  padding-bottom: 0.5rem;
}

.cronica-images-content,
.cronica-audios-content,
.cronica-videos-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1.5rem;
}

.cronica-images-content figure,
.cronica-audios-content figure,
.cronica-videos-content figure {
  flex: 1 1 calc(50% - 1.5rem); /* Ajusta al 50% del contenedor menos el gap */
  max-width: calc(50% - 1.5rem); /* Asegura un ancho máximo igual al cálculo */
  min-width: calc(50% - 1.5rem); /* Consistencia entre flex y tamaños mínimos */
  border: 1px solid #eee;
  border-radius: 5px;
  overflow: hidden;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
}

/* Imagenes adicionales */
.cronica-images-content figure img {
  width: 100%;
  height: 250px; /* altura fija para mantener uniformidad */
  object-fit: cover; /* Ajusta la imagen sin deformarla, recortando si es necesario */
  display: block;
}

/* Audios adicionales */
.cronica-audios-content figure audio {
  width: 100%;
  object-fit: cover; /* Ajusta la imagen sin deformarla, recortando si es necesario */
  display: block;
}

/* Videos adicionales */
.cronica-videos figure video {
  width: 100%;
  height: 250px; /* altura fija para mantener uniformidad */
  display: block;
}

/* Epígrafes */
.cronica-images-content figure figcaption,
.cronica-audios-content figure figcaption,
.cronica-videos-content figure figcaption {
  padding: 0.5rem;
  font-size: 0.8rem;
  color: #555;
  text-align: center;
}

/* --------------------------------------------- */
/* --------------- COMMENTS -------------------- */
/* --------------------------------------------- */
.cronica-comments {
  width: clamp(300px, 100%, 800px);
  margin: 3rem auto 2rem;
}

.cronica-comments .cronica-comments-form {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.cronica-comments .cronica-comments-form textarea {
  font-size: 0.85rem;
  resize: none;
  width: 100%;
  height: 100px;
  overflow-y: scroll;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 0.5rem;
}

.cronica-comments .cronica-comments-form button {
  cursor: pointer;
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  padding: 0.35rem 1rem;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  width: 150px;
  align-self: flex-end;
}

.cronica-comments .cronica-comments-details {
  margin: 2rem auto;
}

.cronica-comments .cronica-comments-details .cronica-comments-details-example {
  background-color: white;
  border: 1px solid lightgray;
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
}

/* --------------- COMMENT HEAD -------------------- */
.cronica-comments
  .cronica-comments-details
  .cronica-comments-details-example
  .cronica-comments-details-example-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cronica-comments
  .cronica-comments-details
  .cronica-comments-details-example
  .cronica-comments-details-example-head
  .comment-author {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.cronica-comments
  .cronica-comments-details
  .cronica-comments-details-example
  .cronica-comments-details-example-head
  .comment-author
  img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.cronica-comments
  .cronica-comments-details
  .cronica-comments-details-example
  .cronica-comments-details-example-head
  .comment-author
  .comment-author-name
  p {
  margin-top: 0.3rem;
}

.cronica-comments
  .cronica-comments-details
  .cronica-comments-details-example
  .cronica-comments-details-example-head
  p {
  font-size: 0.7rem;
  color: gray;
}

/* --------------- COMMENT BODY -------------------- */
.cronica-comments
  .cronica-comments-details
  .cronica-comments-details-example
  .cronica-comments-details-example-body
  p {
  border-top: 1px solid lightgray;
  margin-top: 1rem;
  padding-top: 1rem;
  font-size: 0.85rem;
}

.cronica-comments
  .cronica-comments-details
  .cronica-comments-details-example
  .cronica-comments-details-example-body
  textarea {
  border: 1px solid lightgray;
  margin-top: 1rem;
  padding-top: 1rem;
  font-size: 0.85rem;
  resize: none;
  width: 100%;
  background-color: rgb(255, 255, 220);
}

/* --------------- COMMENT REACTIONS -------------------- */

.cronica-comments
  .cronica-comments-details
  .cronica-comments-details-example
  .cronica-comments-details-example-buttons {
  margin-top: 1rem;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.cronica-comments
  .cronica-comments-details
  .cronica-comments-details-example
  .cronica-comments-details-example-buttons
  .comment-reaction
  button {
  display: flex;
  gap: 0.3rem;
}

.comment-reaction .editing-btn {
  color: orange;
}

.comment-reaction .reply-btn {
  color: green;
}

.comment-reaction .non-reply-btn {
  color: var(--fourth-color);
}

.editing-btn-menu {
  display: flex;
  gap: 0.5rem;
}

.editing-btn-menu-save {
  margin-left: 3rem;
  background-color: var(--primary-color);
  color: white;
  padding: 0.3rem;
  border-radius: 5px;
}

.editing-btn-menu-cancel {
  background-color: var(--fourth-color);
  color: white;
  padding: 0.3rem;
  border-radius: 5px;
}

/* --------------- MY REPLY -------------------- */
.cronica-comments
  .cronica-comments-details
  .cronica-comments-details-example
  .cronica-comments-details-example-myreply {
  margin-top: 1.5rem;
}

.cronica-comments
  .cronica-comments-details
  .cronica-comments-details-example
  .cronica-comments-details-example-myreply
  form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.cronica-comments
  .cronica-comments-details
  .cronica-comments-details-example
  .cronica-comments-details-example-myreply
  form
  textarea {
  font-size: 0.85rem;
  resize: none;
  width: 100%;
  height: 100px;
  overflow-y: scroll;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 0.5rem;
}

.cronica-comments
  .cronica-comments-details
  .cronica-comments-details-example
  .cronica-comments-details-example-myreply
  form
  button {
  font-size: 0.8rem;
  cursor: pointer;
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  padding: 0.35rem 1rem;
  border-radius: 5px;
  color: white;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  width: 100px;
  align-self: flex-end;
}

/* --------------- COMMENT REPLIES -------------------- */
.cronica-comments
  .cronica-comments-details
  .cronica-comments-details-example
  .cronica-comments-details-example-replies {
  margin: 2.5rem 0 0 4rem;
  padding-left: 1rem;
  border-left: 5px solid lightgray;
}

.cronica-comments
  .cronica-comments-details
  .cronica-comments-details-example
  .cronica-comments-details-example-replies
  .cronica-comments-details-example-reply {
  margin-bottom: 1.5rem;
}

/* --------------------------------------------- */
/* --------------- MEDIA QUERIES --------------- */
/* --------------------------------------------- */

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 750px) {
  .cronica-author .cronica-author-details .cronica-author-details-kpi .to-hide {
    display: none;
  }

  .cronica-comments
    .cronica-comments-details
    .cronica-comments-details-example
    .cronica-comments-details-example-head
    .response-date {
    display: none;
  }

  .editing-btn-menu-save {
    margin-left: 0rem;
  }

  .cronica-images-content,
  .cronica-audios-content,
  .cronica-videos-content {
    flex-direction: column;
    gap: 1rem;
  }

  .cronica-images-content figure,
  .cronica-audios-content figure,
  .cronica-videos-content figure {
    flex: 1 1 100%;
    max-width: 100%;
  }

  @media only screen and (max-width: 500px) {
  }
}
