.sidebar {
  height: 100vh;
  width: 280px;
  background-color: white;
  z-index: 1000;
  position: fixed;
  top: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.sidebar-active {
  box-shadow: 0px 0px 0px 2000px rgba(0, 0, 0, 0.3);
  transform: translateX(0);
}

.sidebar-title {
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 1rem 0 1rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid;
  border-color: var(--third-color);
}

.sidebar-logo-text {
  font-size: 1.4rem;
  font-family: var(--accent-font);
  color: var(--primary-color);
  background-color: transparent;
}

.sidebar-logo-text span {
  font-family: inherit;
  background-color: transparent;
  color: var(--third-color);
}

.sidebar-close-btn {
  cursor: pointer;
  background-color: var(--fourth-color);
  color: white;
  border-radius: 20%;
}

.sidebar-links {
  background-color: transparent;
  margin: 8rem 1rem 0 1rem;
}

.sidebar-links ul {
  background-color: transparent;
  list-style: none;
  padding: 0;
}

.sidebar-links ul li {
  background-color: transparent;
}

.sidebar-links ul li a {
  background-color: transparent;
  text-decoration: none;
  line-height: 50px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.sidebar-links ul li a:hover {
  background-color: var(--primary-color);
}

.sidebar-links ul li a:hover span,
.sidebar-links ul li a:hover p {
  scale: 1.1;
  color: white;
}

.sidebar-links ul li a span {
  background-color: transparent;
  color: var(--primary-color);
  margin-left: 1rem;
}

.sidebar-links ul li a p {
  background-color: transparent;
  color: var(--primary-color);
  margin-left: 1rem;
}
