/* ---------- podrida home container ---------- */
.podrida-home-panel-container {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
}

.podrida-home-panel-container h2:first-child {
  margin-bottom: 0.5rem;
}

/* ---------- podrida swiper ---------- */
.podrida-swiper-container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.podrida-swiper {
  width: 100%;
  height: 450px;
}

.podrida-swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.podrida-swiper::part(bullet) {
  background: var(--primary-color);
}

.podrida-swiper::part(bullet-active) {
  background: var(--primary-color);
}

.podrida-swiper-btn-back,
.podrida-swiper-btn-next {
  background-color: #000;
  cursor: pointer;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 500;
  transition: all ease-in-out 0.3s;
}

.podrida-swiper-btn-back {
  left: -2.2rem;
}

.podrida-swiper-btn-next {
  right: -2.2rem;
}

.podrida-swiper-btn-back span,
.podrida-swiper-btn-next span {
  color: white;
  transition: all ease-in-out 0.3s;
}

.podrida-swiper-btn-back:hover,
.podrida-swiper-btn-next:hover {
  background-color: white;
  border: 1px solid;
  border-color: var(--primary-color);
}

.podrida-swiper-btn-back:active,
.podrida-swiper-btn-next:active {
  scale: 0.8;
}

.podrida-swiper-btn-back:hover span,
.podrida-swiper-btn-next:hover span {
  color: var(--primary-color);
}

/* ---------- podrida home container ---------- */
.podrida-home-panel-container-select {
  font-size: 0.85rem;
  border: 1px solid;
  border-color: var(--third-color);
  background-color: var(--third-color);
  border-radius: 10px;
  padding: 0.5rem;
  font-weight: 700;
}

/* ---------- podrida home points title ---------- */
.podrida-home-panel-container h2 {
  margin: 1rem 0 2rem;
  font-family: var(--secondary-font);
  font-size: 1.2rem;
  font-weight: 900;
  text-transform: uppercase;
}

/* ---------- podrida home stats content ---------- */
.podrida-stats-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  gap: 2rem;
}

.podrida-stats-content-card {
  background-color: white;
  flex: 0 0 calc(50% - 1rem);
  display: flex;
  border-radius: 20px;
  min-width: 300px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: all ease 0.3s;
}

.podrida-stats-content-card:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

.podrida-stats-content-card-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: color-mix(in srgb, var(--secondary-color), transparent 50%);
  border-radius: 20px;
  width: 50%;
  padding: 1rem;
  transition: all ease 0.3s;
}

.podrida-stats-content-card:hover .podrida-stats-content-card-top {
  background-color: var(--secondary-color);
}

.podrida-stats-content-card:hover .podrida-stats-content-card-top * {
  color: white;
  transition: all ease 0.3s; /* Add transition for text color */
}

.podrida-stats-content-card-top h5 {
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-align: center;
  font-weight: 400;
  background-color: white;
  padding: 0.3rem;
  border-radius: 10px;
}

.podrida-stats-content-card:hover .podrida-stats-content-card-top h5 {
  color: inherit;
}

.podrida-stats-content-card-top > p {
  font-size: 1rem;
  margin-top: 1rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
}

.podrida-stats-content-card-top-goals {
  border-radius: 50%;
  background-color: var(--secondary-color);
  aspect-ratio: 1/1;
  width: 130px;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.podrida-stats-content-card-top-goals span {
  font-weight: 900;
  text-align: center;
  font-size: 3.5rem;
  color: white;
}

.podrida-stats-content-card-top-goals p {
  font-weight: 300;
  font-size: 0.85rem;
  text-align: center;
  color: white;
}

.podrida-stats-content-card-top-average {
  font-size: 0.85rem;
  text-align: center;
}

.podrida-stats-content-card:hover .podrida-stats-content-card-top-goals {
  background-color: white;
  transition: all ease 0.3s;
}

.podrida-stats-content-card:hover .podrida-stats-content-card-top-goals,
.podrida-stats-content-card:hover .podrida-stats-content-card-top-goals span,
.podrida-stats-content-card:hover .podrida-stats-content-card-top-goals p {
  color: var(--primary-color);
}

.podrida-stats-content-card-rest {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  padding: 1rem;
  width: 50%;
  font-size: 0.85rem;
}

.podrida-stats-content-card-rest p {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.podrida-stats-content-card-rest span {
  font-weight: 900;
  background-color: var(--third-color);
  border-radius: 50%;
  width: 25px;
  aspect-ratio: 1/1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ---------- podrida home points content ---------- */
.podrida-points-content {
  display: flex;
  gap: 2rem;
}

/* ---------- podrida home points main table ---------- */
.podrida-points-main-table {
  background-color: white;
  font-size: 0.85rem;
  border-radius: 20px;
  overflow-x: scroll;
  display: inline-block;
  width: 50%;
  height: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: all ease 0.3s;
}

.podrida-points-main-table:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

.podrida-points-main-table table {
  width: 100%;
}

.podrida-points-main-table thead {
  background-color: var(--primary-color);
}

.podrida-points-main-table thead tr th {
  color: white;
}

.podrida-points-main-table tbody tr:nth-child(even) {
  background-color: color-mix(in srgb, var(--third-color), transparent 75%);
}

.podrida-points-main-table tbody tr:hover {
  background-color: var(--third-color);
  font-weight: 800;
}

.podrida-points-main-table tbody tr td {
  text-align: center;
}

/* ---------- podrida home points cards ---------- */
.podrida-points-cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
}

.top-players-card {
  display: flex;
  position: relative;
  align-items: center;
  border-radius: 20px;
  background-color: white;
  font-size: 0.85rem;
  padding: 1rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: all ease 0.3s;
}

.top-players-card:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

.top-players-card img {
  position: absolute;
  top: -20px;
  left: -20px;
  width: 60px;
}
.top-players-card h5 {
  font-family: var(--accent-font);
  font-size: 1rem;
  font-weight: 400;
  width: 120px;
}

.top-players-card ul {
  list-style: none;
  padding-left: 1rem;
  border-left: 2px dotted;
  border-color: var(--primary-color);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.top-players-card ul li {
  display: flex;
  align-items: center;
}

.top-players-card ul li:first-child {
  background-color: var(--third-color);
  border-radius: 10px;
}

.top-players-card ul li:first-child p,
.top-players-card ul li:first-child span {
  font-size: 1.2rem;
  font-weight: 500;
  padding-block: 0.5rem;
}

.top-players-card ul li p {
  width: 25px;
  text-align: center;
}

.top-players-card ul li span {
  margin-left: 0.5rem;
  padding-inline: 0.5rem;
  padding-block: 0.2rem;
}

/* ---------- Media queries ---------- */
@media only screen and (max-width: 750px) {
  .podrida-stats-content {
    flex-direction: column;
  }

  .podrida-stats-content-card {
    flex-direction: column;
  }

  .podrida-stats-content-card-top,
  .podrida-stats-content-card-rest {
    width: 100%;
  }

  .podrida-points-content {
    flex-direction: column;
  }

  .podrida-stats-content-card {
    width: 100%;
  }

  .podrida-points-main-table {
    width: 100%;
  }

  .podrida-points-cards {
    width: 100%;
  }

  .podrida-swiper-btn-back {
    left: -0.5rem;
  }

  .podrida-swiper-btn-next {
    right: -0.5rem;
  }
}
