/* BUTTON RESET */
button,
a {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: inherit;
  color: inherit;
}

button span,
a span {
  font-size: 24px;
  margin: 0;
}

a {
  position: relative;
  text-decoration: none;
}

.tooltip-text {
  display: none;
  position: fixed;
  font-size: 0.7rem;
  background-color: var(--primary-color);
  padding: 0.25rem 1rem;
  color: white;
  font-weight: 400;
  border-radius: 20px;
  height: 24px;
  z-index: 10000;
  margin-left: 0.3rem;
}

a img {
  width: 36px;
  margin-inline: 1rem;
}

/* OPEN VOTE BUTTON / LINK */
.open-vote {
  background-color: green;
  color: white;
}

/* CLOSE VOTE BUTTON / LINK */
.close-vote {
  background-color: #e74c3c;
  color: white;
}

/* CREATE STAT BUTTON / LINK */
.create-stat-button {
  color: green;
  margin: 0 0.2rem;
}

/* VIEW BUTTON / LINK */
.view-button {
  color: #3498db;
  margin: 0 0.2rem;
}

/* EDIT BUTTON / LINK */
.edit-button {
  color: #f39c12;
  margin: 0 0.2rem;
}

/* DELETE BUTTON / LINK */
.delete-button {
  color: #e74c3c;
  margin: 0 0.2rem;
}

/* HOVER EFFECTS */
button:hover span,
a:hover > span {
  scale: 1.1;
  transition: all ease 0.3s;
}

a:hover span:nth-child(2),
button:hover span:nth-child(2) {
  display: inline-block;
}

/* ---------- DELETE MODAL ---------- */
.delete-confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}
.delete-confirmation-modal {
  background: #fff;
  padding: 1.5rem;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: clamp(0px, 80%, 500px);
  text-align: center;
}

.delete-confirmation-modal h4 {
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.delete-confirmation-modal p {
  margin-bottom: 3.5rem;
  font-size: 0.85rem;
}

.delete-confirmation-btn-container {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.delete-confirmation-modal button {
  padding: 0.5rem 1rem;
  cursor: pointer;
  width: 120px;
  border-radius: 20px;
  text-transform: uppercase;
  color: white;
}

.delete-confirmation-modal button:first-child {
  color: green;
  border: 1px solid green;
  background-color: white;
  justify-content: center;
}

.delete-confirmation-modal button:first-child:hover {
  color: white;
  background-color: green;
}

.delete-confirmation-modal button:last-child {
  color: red;
  border: 1px solid red;
  background-color: white;
  justify-content: center;
}

.delete-confirmation-modal button:last-child:hover {
  color: white;
  background-color: red;
}
