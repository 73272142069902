/* ---------- Chachos tournament rounds container ---------- */
.chachos-tournament-rounds-container {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* ---------- Chachos home panel title ---------- */
.chachos-tournament-rounds-container h2 {
  margin: 1rem 0 2rem;
  font-family: var(--secondary-font);
  font-size: 1.2rem;
  font-weight: 900;
  text-transform: uppercase;
  align-self: flex-start;
}

/* ---------- Chachos home panel main table ---------- */
.chachos-tournament-rounds-main-table {
  background-color: white;
  font-size: 1rem;
  border-radius: 20px;
  overflow: hidden;
  display: inline-block;
  width: 80%;
  height: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: all ease 0.3s;
}

.chachos-tournament-rounds-main-table:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

.chachos-tournament-rounds-main-table table {
  width: 100%;
}

.chachos-tournament-rounds-main-table thead {
  background-color: var(--primary-color);
}

.chachos-tournament-rounds-main-table thead tr th {
  color: white;
}

.chachos-tournament-rounds-main-table tbody tr:nth-child(even) {
  background-color: color-mix(in srgb, var(--third-color), transparent 75%);
}

.chachos-tournament-rounds-main-table tbody tr:hover {
  background-color: var(--third-color);
  font-weight: 800;
}

.chachos-tournament-rounds-main-table tbody tr td {
  text-align: center;
}

/* ---------- Media queries ---------- */

@media only screen and (max-width: 750px) {
  .chachos-tournament-rounds-main-table {
    width: 100%;
  }

  .chachos-tournament-rounds-main-table {
    font-size: 0.85rem;
  }
}
