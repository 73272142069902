.reset-password-container {
  width: 80%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 3rem;
}

/* --------------- HERO TEXT --------------- */
.reset-password-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: clamp(100px, 75vh, 500px);
  border: 1px lightgray solid;
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
}

.reset-password-brand {
  font-family: var(--accent-font);
  font-size: 2rem;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 1rem;
}

.reset-password-brand span {
  font-family: var(--accent-font);
  color: var(--third-color);
}

.reset-password-content img {
  width: 100px;
  border: 1px lightgray solid;
  border-radius: 50%;
}

.reset-password-content h4 {
  margin-top: 2rem;
  font-size: 1.2rem;
  font-weight: 400;
  text-align: center;
}

.reset-password-content h4 span {
  font-family: var(--primary-font);
  color: var(--secondary-color);
  font-weight: 800;
}

.reset-password-content h6 {
  margin-top: 1rem;
  font-size: 0.8rem;
  font-weight: 300;
  text-align: center;
  color: grey;
}

.reset-password-content form {
  margin-top: auto;
  font-size: 0.85rem;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  width: 100%;
}

.reset-password-content form label {
  display: inline-block;
  width: 35%;
  font-weight: 700;
}

.reset-password-content form input {
  display: inline-block;
  width: 65%;
  font-size: 0.85rem;
  border: 1px solid;
  border-color: var(--third-color);
  background-color: var(--third-color);
  max-width: calc(100% - 80px);
  border-radius: 10px;
  padding: 0.5rem;
  font-weight: 700;
}

.reset-password-content form button {
  margin-top: 0.5rem;
  width: 200px;
  align-self: center;
  text-align: center;
  padding: 0.5rem;
  background-color: var(--primary-color);
  color: white;
  border-radius: 10px;
  transition: all ease 0.3s;
}

.reset-password-content form button:hover {
  scale: 1.05;
}

.reset-password-content form .message-error {
  color: var(--fourth-color);
  font-size: 0.85rem;
  text-align: center;
}

.reset-password-content form .message-success {
  color: green;
  font-size: 0.85rem;
  text-align: center;
}

/* --------------- MEDIA QUERIES --------------- */

@media only screen and (max-width: 769px) {
  .reset-password-content {
    width: 300px;
  }
  .reset-password-brand {
    margin-top: 0rem;
  }

  .reset-password-content h4 {
    margin-top: 1rem;
  }
}
