.user-menu {
  height: 450px;
  width: 280px;
  background-color: white;
  z-index: 850;
  position: fixed;
  top: 0;
  right: 0;
  padding: 1rem;
  transform: translateY(80px);
  transition: transform 0.3s ease;
  border-bottom-left-radius: 20px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
}

.user-menu-active {
  transform: translateY(-450px);
}

/* --------------- USER MENU TITLE --------------- */

.user-menu-title {
  background-color: transparent;
  padding-bottom: 1rem;
  border-bottom: 2px solid;
  border-color: var(--third-color);
}

.user-menu-close-btn {
  cursor: pointer;
  background-color: var(--fourth-color);
  color: white;
  border-radius: 20%;
  position: absolute;
  top: 1.2rem;
  left: 1.5rem;
}

.user-menu-title p {
  background-color: transparent;
  text-align: end;
}

.user-menu-title p:first-child {
  font-weight: 500;
  font-size: 0.9rem;
}

.user-menu-title p:last-child {
  font-weight: 300;
  font-size: 0.75rem;
}

/* --------------- USER MENU OPTIONS --------------- */

.user-menu-options {
  margin-top: 1rem;
  padding-bottom: 1rem;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid;
  border-color: var(--third-color);
}

.user-menu-options a {
  display: flex;
  gap: 1rem;
  padding: 0.5rem;
  align-items: center;
  background-color: transparent;
  text-decoration: none;
}

.user-menu-options a span {
  background-color: transparent;
}
.user-menu-options a p {
  background-color: transparent;
  font-size: 0.9rem;
}

.user-menu-options a:hover {
  background-color: var(--third-color);
  border-radius: 10px;
}

/* --------------- USER MENU SOCIALS --------------- */

.user-menu-social {
  background-color: transparent;
  margin-top: 1rem;
}

.user-menu-social p {
  background-color: transparent;
  font-size: 0.9rem;
}

.user-menu-social-icons {
  background-color: transparent;
  display: flex;
  justify-content: center;
  gap: 3rem;
  padding-top: 0.8rem;
}

.user-menu-social-icons a {
  background-color: transparent;
}

.user-menu-social-icons a i {
  background-color: transparent;
  font-size: 1.3rem;
}

/* --------------- USER MENU LOGOUT --------------- */

.logout-container button {
  display: flex;
  padding: 0.5rem;
  margin-bottom: 1rem;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: calc(280px - 2rem);
  border-radius: 10px;
}

.logout-container button span,
.logout-container button p {
  color: var(--fourth-color);
  background-color: transparent;
}

.logout-container button p {
  font-size: 0.9rem;
}

.logout-container button p {
  padding-left: 1rem;
}

.logout-container button:hover,
.logout-container button:hover span,
.logout-container button:hover p {
  background-color: var(--fourth-color);
  color: white;
}
