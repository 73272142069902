/* ---------- IMAGE SLIDER ITEM ---------- */
.img-slider-list .img-slider-item {
  position: absolute;
  inset: 0 0 0 0;
  overflow: hidden;
  opacity: 0;
  transition: 0.5s;
}

/* ---------- IMAGE SLIDER ITEM ACTIVE ---------- */
.img-slider-list .img-slider-item.img-slider-active {
  opacity: 1;
  z-index: 50;
}

/* ---------- IMAGE SLIDER GRADIENT ---------- */
.img-slider-list .img-slider-item::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: linear-gradient(to top, #0000003c 0%, transparent);
}

/* ---------- IMAGE SLIDER IMAGE ---------- */
.img-slider-list .img-slider-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* ---------- IMAGE SLIDER CONTENT ---------- */
.img-slider-list .img-slider-item .img-slider-item-content {
  position: absolute;
  left: 5%;
  bottom: 10%;
  width: 500px;
  max-width: 80%;
  z-index: 10;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.3);
  padding: 1rem;
}

.img-slider-list .img-slider-item .img-slider-item-content p:nth-child(1) {
  text-transform: uppercase;
  letter-spacing: 10px;
}

.img-slider-list .img-slider-item .img-slider-item-content h2 {
  font-size: 4rem;
  margin: 0;
}

.img-slider-list .img-slider-item.img-slider-active p:nth-child(1),
.img-slider-list .img-slider-item.img-slider-active h2,
.img-slider-list .img-slider-item.img-slider-active p:nth-child(3) {
  color: white;
  transform: translateY(30px);
  filter: blur(20px);
  opacity: 0;
  animation: showContent 0.5s 0.7s ease-in-out 1 forwards;
}

.img-slider-list .img-slider-item.img-slider-active h2 {
  animation-delay: 1s;
}

.img-slider-list .img-slider-item.img-slider-active p:nth-child(3) {
  animation-delay: 1.3s;
}

/* ---------- IMAGE SLIDER ARROWS ---------- */
#prev-img,
#next-img {
  position: absolute;
  top: 30%;
  z-index: 100;
  background-color: black;
  border: none;
  font-family: monospace;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  font-size: x-large;
  color: #eee;
  transition: 0.5s;
}

#next-img {
  right: 0%;
}

#prev-img:hover,
#next-img:hover {
  font-family: monospace;
}

/* ---------- KEYFRAMES & ANIMATIONS ---------- */
@keyframes showContent {
  to {
    transform: translateY(0);
    filter: blur(0);
    opacity: 1;
  }
}

/* MEDIA QUERIES */
@media only screen and (max-width: 800px) {
  .img-slider-list .img-slider-item img {
    object-fit: cover;
  }

  .img-slider-list .img-slider-item .img-slider-item-content {
    left: 5%;
  }
}

@media only screen and (max-width: 600px) {
  .img-slider-list .img-slider-item .img-slider-item-content {
    bottom: 5%;
  }

  .img-slider-list .img-slider-item .img-slider-item-content h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  .img-slider-list .img-slider-item .img-slider-item-content p {
    font-size: 0.9rem;
  }
}
