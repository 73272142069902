/* ---------- Votes results container ---------- */
.votes-results-container {
  margin-bottom: 3rem;
}

/* ---------- Votes results title ---------- */
.votes-results-title {
  display: flex;
  align-items: center;
  margin: 1rem 0 2rem;
}

.votes-results-title h2 {
  font-family: var(--secondary-font);
  font-size: 1.2rem;
  font-weight: 900;
  text-transform: uppercase;
}

.votes-results-title .return-link {
  text-decoration: none;
  margin-left: 3rem;
  font-size: 0.8rem;
  background-color: var(--fourth-color);
  color: white;
  border-radius: 10px;
  padding: 0.5rem 1rem;
  transition: all ease 0.3s;
}

/* ---------- Votes results content ---------- */
.votes-results-content {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "match-details evaluation"
    "pearls evaluation";

  background-color: white;
  padding: 1rem;
  border-radius: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.votes-results-content h4 {
  margin-bottom: 1rem;
}

.votes-results-content h4 span {
  color: var(--fourth-color);
  font-weight: 900;
}

.votes-results-content p {
  font-size: 0.9rem;
}

.votes-results-content:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

/* ---------- Votes results match details ---------- */
.votes-results-content .votes-results-match-details {
  grid-area: match-details;
}

.votes-results-content .votes-results-match-details span {
  font-weight: 900;
}

/* ---------- Votes results pearls details ---------- */
.votes-results-content .votes-results-pearls-details {
  grid-area: pearls;
  display: flex;
  flex-direction: column;
}

.pearl-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border: 1px solid;
  border-color: var(--third-color);
  border-radius: 10px;
}

.pearl-container:not(:first-child):not(:nth-child(2)) {
  margin-top: 0.8rem;
}

.pearl-container img {
  height: 70px;
}

.pearl-container .pearl-winners {
  flex-grow: 1;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pearl-container .pearl-winners .pearl-winners-names {
  font-weight: 900;
}

.pearl-container .pearl-list-detail {
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem 0.5rem 0.5rem;
}

.pearl-container .pearl-list-detail .player-detail {
  display: flex;
  align-items: center;
  height: 20px;
}

.pearl-container .pearl-list-detail p {
  color: var(--secondary-color);
  font-size: 0.85rem;
  width: 200px;
}

.pearl-container .pearl-list-detail .player-detail span {
  font-size: 0.85rem;
  font-weight: 900;
}

.pearl-container .pearl-list-detail .player-detail .player-votes {
  background-color: var(--third-color);
  width: 20px;
  border-radius: 50%;
  text-align: center;
  margin-right: 1rem;
}

/* ---------- Votes results evaluation details ---------- */
.votes-results-content .votes-results-evaluation-container {
  grid-area: evaluation;
  display: flex;
  flex-direction: column;
}

.votes-results-evaluation-table {
  background-color: white;
  font-size: 0.85rem;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  transition: all ease 0.3s;
}

.votes-results-evaluation-table table {
  width: 100%;
}

.votes-results-evaluation-table thead {
  background-color: var(--primary-color);
}

.votes-results-evaluation-table thead tr th {
  color: white;
}

.votes-results-evaluation-table tbody tr:nth-child(even) {
  background-color: color-mix(in srgb, var(--third-color), transparent 75%);
}

.votes-results-evaluation-table tbody tr:hover {
  background-color: var(--third-color);
  font-weight: 800;
}

.votes-results-evaluation-table tbody tr td {
  text-align: center;
}

/* ---------- Media queries ---------- */
@media only screen and (max-width: 600px) {
  .votes-results-content {
    grid-template-columns: 1fr;
    grid-template-areas:
      "match-details"
      "pearls"
      "evaluation";

    padding: 1rem;
  }
}
