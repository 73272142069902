/* ---------- Admin Chachos container ---------- */
.admin-chachos-panel-container {
  margin-top: 1rem;
  margin-bottom: 3rem;
  display: flex;
}

/* ---------- Chachos squad title ---------- */
.chachos-squad-container h2 {
  margin: 1rem 0 2rem;
  font-family: var(--secondary-font);
  font-size: 1.2rem;
  font-weight: 900;
  text-transform: uppercase;
  align-self: flex-start;
}

.chachos-squad-container h2:not(:first-child) {
  margin-top: 2rem;
}

/* ---------- Chachos squad main table ---------- */
.chachos-squad-main-table {
  background-color: white;
  font-size: 1rem;
  border-radius: 20px;
  overflow: hidden;
  display: inline-block;
  width: 80%;
  height: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  transition: all ease 0.3s;
}

.chachos-squad-main-table:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
}

.chachos-squad-main-table table {
  width: 100%;
}

.chachos-squad-main-table thead {
  background-color: var(--primary-color);
}

.chachos-squad-main-table thead tr th {
  color: white;
}

.chachos-squad-main-table tbody tr:nth-child(even) {
  background-color: color-mix(in srgb, var(--third-color), transparent 75%);
}

.chachos-squad-main-table tbody tr:hover {
  background-color: var(--third-color);
  font-weight: 800;
}

.chachos-squad-main-table tbody tr td {
  text-align: center;
}

.chachos-squad-main-table tbody tr td a {
  text-decoration: none;
}

.chachos-squad-main-table tbody tr td a span {
  color: var(--secondary-color);
  font-weight: bold;
}

.chachos-squad-main-table .view-profile {
  font-size: 0.8rem;
  padding: 0.25rem;
  background-color: var(--secondary-color);
  color: white;
  border-radius: 5px;
  display: inline-block;
  width: 45px;
}

/* ---------- Media queries ---------- */

@media only screen and (max-width: 750px) {
  .chachos-squad-main-table {
    width: 100%;
  }

  .chachos-squad-main-table {
    font-size: 0.85rem;
  }
}
